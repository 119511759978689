body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-inputs{
  max-width: 500px;
}

.card-forms {
  max-width: 600px;
  margin-top: 30px;
}

.button {
  background-color: red;
  color: white
}

@media print {
  @page {
    size: landscape;
  }
  body * {
    visibility: hidden;
  }
  .section-to-print, .section-to-print * {
    visibility: visible;
  }
  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100vh;
    width: 100%
  }
}

.background-brasa {
    position: relative;
    background: #548E40;
    background: linear-gradient(0deg, rgba(56, 56, 56, 0.3), rgba(30, 30, 28, 0.3)), url(/static/media/brasa-background.60eeaf21.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
}

.background-brasa .box {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: white;
    padding: 40px 50px;
    width: 30%;
    text-align: center;
    box-shadow: 0 0 10px 0 #555;
}

.background-brasa .box .text-instruction {
    text-align: left;
    margin-bottom: 50px;
}

.background-brasa .box .text-instruction h1 {
    font-weight: 800;
}

.background-brasa .box .google-login {
    width: 48%;
    padding: 10px 15px;
    text-align: center;
    border: none;
    border-radius: 25px;
    background: #1E1E1C;
    color: white;
    font-weight: 800;
    cursor: pointer;
    transition: 0.4s;
    line-height: 1.5em;
}

.background-brasa .box .google-login:hover {
    background: #383838;
    transition: 0.4s;
}

.background-brasa .box .google-login img {
    float: left;
    margin-right: 10px;
    height: 1.5em;
}

.background-brasa .box .google-login span {
    height: 100%;
    color: white;
    font-weight: 800;
}

.error{
    display: none;
    margin-top: 36px;
    color: red;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media (max-width: 1024px) {
    .background-brasa .box {
        width: 60%;
    }
    .background-brasa .box .google-login {
        width: 60%;
    }
    .mobile-margin{
        margin-top: 2em;
        margin-bottom: 2em;
    }
}

@media (max-width: 578px) {
    .background-brasa .box {
        width: 90%;
    }
    .background-brasa .box .google-login {
        width: 60%;
    }
}
main {
    background-color: #F6F7F7;
}

.main.container{
    min-height: 100vh;
    padding-top: 60px;
}

.main.container h1{
    font-weight: bold;
    margin-bottom: 40px;
}

hr{
    background-color:  #383838;
    height: 3px;
}

.text-sm-right{
    text-align: right;
}

.card-body{
    border-radius: 10px;
    border: none;
}

.card{
    border: none;
}

.shadow-hover:hover .number-circle{
    box-shadow: 0 0 10px 0 #1E1E1C;
}

.shadow-hover:hover .card-body{
    box-shadow: 0 0 4px 0 #1E1E1C;
}

iframe{
    overflow:hidden;
    left: 50%;
    min-height: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
}

.modal-content {
    min-height: 95%;
    border-radius: 0;
    top: 0;
    vertical-align: middle;
}

#arrow-left , #arrow-right {
    text-shadow: #1E1E1C 0 0 8px;
    cursor: pointer;
    pointer-events: auto;
}

.number-circle {
    background: #fff;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    display: inline-block;
    font-weight: bold;
    line-height: 8vw;
    text-align: center;
    width: 8vw;
    font-size: 30pt;
}

.desc-stats{
    font-size: 2em;
    line-height: 8vw;
    color: white;
    font-weight: bold;
    margin: 0;
}

.list-times p{
    font-size: 2em;
    line-height: 5vw;
    color: #1E1E1C;
    font-weight: bold;
}

.list-times .card-body{
    background-color: #D9D9D9;
    border: 4px solid #F6F7F7;
}

.list-times .card-body:hover{
    border: 4px solid #383838;
}

.submit-form{
    background-color: #1E1E1C;
    width: 50%;
    border: none;
}

.submit-form:hover{
    background-color: #383838;
}

.statis-link{
    cursor: pointer;
    display: block;
    text-align: center;
    color: #383838;
    font-size: 2em;
    line-height: 3vw;
    font-weight: bold;
    padding: 1.25rem;
}

.statis-link:hover{
    color: #1E1E1C;
}

.statis-link i{
    padding-left: 15px;
}

.time-collpase-title{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    color: #ffffff;
    font-size: 2em;
}

.time-collpase-title:focus, .time-collpase-title:active{
    box-shadow: none;
}

.time-collpase-title i{
    color: #ffffff;
    font-weight: 900;
}

.table .thin-column{
    text-align: center;
    width: 10%;
}

.select-filter-stats{
    padding: 15px;
    border: #383838 4px solid;
    border-radius: 15px;
    font-size: 1.2em;
    width: 100%;
}

.select-filter-stats:focus{
    outline: none;
}

.export-excel{
    cursor: pointer;
    font-size: 2.2em;
    color: inherit;
}

#delete-modal{
    background: rgba(10,10,10,0.8);
}

.linkSelected{
    font-size: 1.5em;
}

.copy-to-clipboard{
    cursor: pointer;
}

.table.referers{
    table-layout: fixed;
}

.table.referers td{
    white-space: nowrap;
    overflow: hidden;
}

.opacity-0{
    opacity: 0;
}

.col-width-80{
    width: 80%;
}

#cv-iframe{
    border: none;
}

@media (max-width: 768px) {
    .number-circle {
        line-height: 20vw;
        width: 20vw;
    }
    .desc-stats{
        font-size: 1.5em;
    }

    .list-times p{
        color: white;
    }

    .list-times .card-body{
        background-color: #1E1E1C;
    }
}

@media(max-width: 575px) {
    .text-sm-right {
        text-align: inherit;
    }
}

.modal-dialog{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
}

.modal {
    display: block;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    background: rgba(0, 0, 0, 0.4);
}

.modal-content{
    border-radius: 6px;
    min-width: 80vw;
}

#cv-modal .body-max-width{
    width: 95%;
    margin: auto;
}

#box-comments {
    margin: 30px;
    text-align: center;
    max-height: 50vh;
}

#header-comments {
    color: #1E1E1C;
    text-align: center;
}

#submit-comment-btn {
    margin-top: 10px;
    width: 100%;
    color: white;
}
header{
    min-height: 10vh;
    display: flex;
    align-items: center;
}

header img {
    width: 4%;
}

header .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#mainMenu ul li a{
    cursor: pointer;
}

#nav-icon {
    z-index: 99;
    width: 60px;
    height: 45px;
    margin-left: 5%;
    position: relative;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 8px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

#nav-icon.open span{
    background-color: #383838;
}

#nav-icon span:nth-child(1) {
    top: 0px;
}

#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
    top: 18px;
}

#nav-icon span:nth-child(4) {
    top: 36px;
}

#nav-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#mainMenu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 90;
    background-color: transparent;
}

#mainMenu .camada-menu{
    background-color: #F6F7F7;
    opacity: 0.9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

#mainMenu ul{
    z-index: 91;
    list-style: none;
    padding: 50px;
    background-color: white;
}

#mainMenu ul li{
    padding: 10px;
}

#mainMenu ul img{
    border-radius: 100%;
    width: 150px;
}

#mainMenu ul li a{
    text-transform: uppercase;
    color: #383838;
    font-size: 2em;
    font-weight: 800;
    transition: 0.3s;
}

#mainMenu ul li a:hover{
    -webkit-text-decoration-color: #184274;
            text-decoration-color: #184274;
}

@media (max-width: 1024px) { /* Tablet */
    header img {
        width: 9%;
    }

    #nav-icon {
        width: 90px;
    }
}

@media (max-width: 415px) { /* Mobile */
    header img {
        width: 14%;
    }

    #nav-icon {
        width: 150px;
    }
}
* {
  font-family: 'Lato', sans-serif;
  color: #1E1E1C;
}

.force-txt-white, .force-txt-white span{
  color: white;
}

.text-white{
  color: white;
}

.text-black{
  color: black;
}

.text-green{
  color: #548E40;
}

.bg-blue{
  background-color: #1887C9;
}

.bg-yellow{
  background-color: #FAE108;
}

.bg-green{
  background-color: #78B243;
}

.bg-dark-blue{
  background-color: #184274;
}

.bg-dark-yellow{
  background-color: #F3C515;
}

.bg-dark-green{
  background-color: #548E40;
}

.bg-white{
  background-color: #F6F7F7;
}

.bg-light-grey{
  background-color: #D9D9D9;
}

.bg-grey{
  background-color: #383838;
}

.bg-dark-grey{
  background-color: #1E1E1C;
}

.middle-transform{
  position: absolute;
  top: 50%; right: 50%;
  -webkit-transform: translate(50%,-50%);
          transform: translate(50%,-50%);
}

.error-page{
  height: 100vh;
}

.error-page h1{
  font-weight: 800;
}

.error-page h2{
  margin-top: 2em;
  font-weight: 600;
  font-size: 3em;
}

@media print {
  .hide-print{
    display: none !important;
  }
}
