header{
    min-height: 10vh;
    display: flex;
    align-items: center;
}

header img {
    width: 4%;
}

header .container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#mainMenu ul li a{
    cursor: pointer;
}

#nav-icon {
    z-index: 99;
    width: 60px;
    height: 45px;
    margin-left: 5%;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: 8px;
    width: 100%;
    background: white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon.open span{
    background-color: #383838;
}

#nav-icon span:nth-child(1) {
    top: 0px;
}

#nav-icon span:nth-child(2),#nav-icon span:nth-child(3) {
    top: 18px;
}

#nav-icon span:nth-child(4) {
    top: 36px;
}

#nav-icon.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#mainMenu{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 90;
    background-color: transparent;
}

#mainMenu .camada-menu{
    background-color: #F6F7F7;
    opacity: 0.9;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

#mainMenu ul{
    z-index: 91;
    list-style: none;
    padding: 50px;
    background-color: white;
}

#mainMenu ul li{
    padding: 10px;
}

#mainMenu ul img{
    border-radius: 100%;
    width: 150px;
}

#mainMenu ul li a{
    text-transform: uppercase;
    color: #383838;
    font-size: 2em;
    font-weight: 800;
    transition: 0.3s;
}

#mainMenu ul li a:hover{
    text-decoration-color: #184274;
}

@media (max-width: 1024px) { /* Tablet */
    header img {
        width: 9%;
    }

    #nav-icon {
        width: 90px;
    }
}

@media (max-width: 415px) { /* Mobile */
    header img {
        width: 14%;
    }

    #nav-icon {
        width: 150px;
    }
}