body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-inputs{
  max-width: 500px;
}

.card-forms {
  max-width: 600px;
  margin-top: 30px;
}

.button {
  background-color: red;
  color: white
}

@media print {
  @page {
    size: landscape;
  }
  body * {
    visibility: hidden;
  }
  .section-to-print, .section-to-print * {
    visibility: visible;
  }
  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100vh;
    width: 100%
  }
}
